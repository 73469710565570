import React, { useState, createContext, useMemo } from 'react';
import { VirtualAgentModal } from '../components/Widgets/VirtualAgent/VirtualAgentModal';
import { useRootContext } from '@jarvis/react-portal-addons';
import { UseRootContextType } from '../types/mfeProps';
import { triggerVirtualAgentClickEvent } from 'src/utils/analytics';

type VirtualAgentContextValueType = {
  showVirtualAgentModal: boolean;
  openVA: () => void;
  setShowVirtualAgentModal?: (value: boolean) => void;
  canShowVA: boolean;
};

export const VirtualAgentContext = createContext<VirtualAgentContextValueType>({
  showVirtualAgentModal: false,
  openVA: () => {},
  setShowVirtualAgentModal: () => {},
  canShowVA: false
});

export const VirtualAgentProvider = ({ children }) => {
  const [showVirtualAgentModal, setShowVirtualAgentModal] = useState(false);
  const { localization }: UseRootContextType = useRootContext();

  const canShowVA = useMemo(() => {
    const supportedLanguages = ['de', 'en', 'es', 'fr', 'ja', 'nl', 'pt', 'zh'];
    const simplifiedChineseCountries = ['cn', 'sg'];
    return (
      supportedLanguages.includes(localization?.language) &&
      (localization?.language !== 'zh' ||
        simplifiedChineseCountries.includes(localization?.country))
    );
  }, [localization?.country, localization?.language]);

  const openVA = () => {
    triggerVirtualAgentClickEvent();
    setShowVirtualAgentModal(true);
  };

  return (
    <VirtualAgentContext.Provider
      value={{
        showVirtualAgentModal,
        openVA,
        setShowVirtualAgentModal,
        canShowVA
      }}
    >
      {children}

      <VirtualAgentModal
        show={showVirtualAgentModal}
        setShow={setShowVirtualAgentModal}
      />
    </VirtualAgentContext.Provider>
  );
};
