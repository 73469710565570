import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/services/AnalyticsService/types';

const SIMPLE_UI_VERSION = '1.5.0';
const EVENTING_EVENT_VERSION = '1.4.0';

const ACTIONS = {
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  MODAL_WINDOW_DISPLAYED: 'ModalWindowDisplayed'
};

const EVENT_DETAIL_DEFAULT_VALUES = {
  activity: 'VirtualAgent-v01',
  version: SIMPLE_UI_VERSION,
  screenPath: '/ReactConsumerSideMenu/'
};

const SCREEN_NAMES = {
  VIRTUAL_AGENT_SUCCESS_MODAL: 'VirtualAgentSuccessModal',
  VIRTUAL_AGENT_ERROR_MODAL: 'VirtualAgentErrorModal',
  SIDE_MENU: 'SideMenu'
};

type EventType = {
  activity: string;
  version: string;
  screenPath: string;
  screenName: string;
  action: string;
  controlName?: string;
  controlDetail?: string;
};

// Event call

const publishEvent = (event: EventType, options?: PublishCdmEventsOptions) => {
  const publishCdmEvents = window.Shell?.v1?.analytics?.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const events = Array.isArray(event) ? event : [event];

  const cdmEvents = events.map((item) => ({
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: item,
    version: EVENTING_EVENT_VERSION
  }));

  publishCdmEvents(cdmEvents, options);
};

// ======= Events values =======

// Side Menu Virtual Agent Click

export const triggerVirtualAgentClickEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    activity: 'ConsumerPortal-v01',
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    screenName: SCREEN_NAMES.SIDE_MENU,
    controlName: 'MenuVirtualAgent'
  });
};

// Success Modal

export const triggerVirtualAgentSuccessModalDisplayedEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    action: ACTIONS.MODAL_WINDOW_DISPLAYED,
    screenName: SCREEN_NAMES.VIRTUAL_AGENT_SUCCESS_MODAL
  });
};

export const triggerStartChatEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    screenName: SCREEN_NAMES.VIRTUAL_AGENT_SUCCESS_MODAL,
    controlName: 'StartChat',
    controlDetail: 'https://virtualagent.hpcloud.hp.com'
  });
};

export const triggerCancelEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    screenName: SCREEN_NAMES.VIRTUAL_AGENT_SUCCESS_MODAL,
    controlName: 'Cancel'
  });
};

// Error Modal

export const triggerVirtualAgentErrorModalDisplayedEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    action: ACTIONS.MODAL_WINDOW_DISPLAYED,
    screenName: SCREEN_NAMES.VIRTUAL_AGENT_ERROR_MODAL
  });
};

export const triggerGetSupportEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    screenName: SCREEN_NAMES.VIRTUAL_AGENT_ERROR_MODAL,
    controlName: 'GetSupport',
    controlDetail: 'https://support.hp.com'
  });
};

export const triggerOkEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    screenName: SCREEN_NAMES.VIRTUAL_AGENT_ERROR_MODAL,
    controlName: 'OK'
  });
};
