import React, { useMemo, useContext, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import { FooterLink } from './FooterLink';
import { UseRootContextType } from '../../../types/mfeProps';
import { VirtualAgentFooter } from './VirtualAgentFooter';
import { VirtualAgentContext } from '../../../contexts/VirtualAgent';
import { Stack } from 'src/types/shell';
import { getFeatureFlag } from 'src/utils/featureFlags';
import {
  FooterContainer,
  FooterContentContainer,
  CopyrightText,
  LinksContainer
} from './styles';

export const Footer = () => {
  const { t } = useI18n();
  const {
    localization: { country, language },
    stack
  }: UseRootContextType = useRootContext();
  const [hpxPortal, setHpxPortal] = useState(null);
  const { canShowVA } = useContext(VirtualAgentContext);
  const showVAFooter = isMobile && canShowVA;

  const patternICPHost = /^(?:www\.|portal\.)?hp-smart(?:stage)?\.cn$/;
  const showICPFooter = new RegExp(patternICPHost).test(window.location.host);

  useEffect(() => {
    getFeatureFlag({ key: 'hpxPortal', defaultValue: false }).then((value) => {
      setHpxPortal(value);
    });
  }, []);

  const LinkList = useMemo(() => {
    return [
      {
        href: `https://www.hp.com/${country}-${language}`,
        content: 'HP.com',
        dataTestId: 'footer-hp-com'
      },
      {
        href: `https://www.hpsmart.com/${country}/${language}/wireless-printing`,
        content: t('footer.wirelessPrintCenter'),
        dataTestId: 'footer-wireless-print-center'
      },
      {
        href: '/help/about-hp-smart',
        content: t('footer.helpCenter'),
        dataTestId: 'footer-help-center'
      },
      hpxPortal !== null && {
        href: `https://www.hpsmart.com/${country}/${language}/tou`,
        content: hpxPortal
          ? t('footer.termsOfUse')
          : t('footer.hpSmartTermsOfUse'),
        dataTestId: 'footer-terms-of-use'
      },
      {
        href: `https://www.hp.com/${country}-${language}/privacy/privacy-central.html`,
        content: t('footer.hpPrivacy'),
        dataTestId: 'footer-hp-privacy'
      },
      {
        href: 'https://www.hp.com/us-en/privacy/your-privacy-choices.html',
        content: t('footer.hpYourPrivacyChoices'),
        show: country === 'us' && language === 'en',
        dataTestId: 'footer-hp-your-privacy-choices'
      },
      {
        href: '#',
        content: t('footer.icpTrademark'),
        show: showICPFooter,
        dataTestId: 'footer-hp-icp-trademark'
      },
      {
        href: 'https://beian.miit.gov.cn/',
        content: t(
          stack === Stack.stage ? 'footer.icpLicenseStage' : 'footer.icpLicense'
        ),
        show: showICPFooter,
        dataTestId: 'footer-hp-icp-license'
      }
    ].filter(Boolean);
  }, [country, language, t, hpxPortal, showICPFooter, stack]);

  return (
    <React.Fragment>
      <FooterContainer data-testid="consumer-footer">
        <FooterContentContainer showVAFooter={showVAFooter}>
          <CopyrightText
            className="caption-small"
            data-testid="consumer-footer-copyright"
          >
            {t('footer.copyRightYear', { year: new Date().getFullYear() })}
          </CopyrightText>
          <LinksContainer>
            {LinkList.map((link) => (
              <React.Fragment key={link.content}>
                {(link.show !== undefined ? link.show : true) && (
                  <FooterLink
                    href={link.href}
                    content={link.content}
                    data-testid={link.dataTestId}
                  />
                )}
              </React.Fragment>
            ))}
          </LinksContainer>
        </FooterContentContainer>
      </FooterContainer>
      {showVAFooter && <VirtualAgentFooter />}
    </React.Fragment>
  );
};
